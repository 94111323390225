import Image from 'next/future/image';

import { EthereumIcon } from '@endaoment-frontend/ui/icons';

import { BoardMembers, Roles, TeamMembers } from './content/about';
import { PortfolioAllocationCards } from './content/donors';
import { HeroSlides } from './content/home';
import { FAQs } from './content/learn/faqSection';
import { MediaCards } from './content/mediaCarousel';
import { Timeline } from './content/ndao';
import carter from './images/avatars/board/carter.png';
import robbie from './images/avatars/board/robbie.png';
import ruth from './images/avatars/board/ruth.png';
import vance from './images/avatars/board/vance.png';
import alexis from './images/avatars/team/alexis.png';
import dae from './images/avatars/team/dae.png';
import dania from './images/avatars/team/dania.png';
import ishaan from './images/avatars/team/ishaan.png';
import noah from './images/avatars/team/noah.png';
import pedro from './images/avatars/team/pedro.png';
import ricardo from './images/avatars/team/ricardo.png';
import zach from './images/avatars/team/zach.png';
import aaveLogo from './images/logos/aave-logo.svg';
import cheddarLogo from './images/logos/cheddar-logo.svg';
import chronicleOfPhilanthropyLogo from './images/logos/chronicle-of-philanthropy-logo.png';
import compoundLogo from './images/logos/compound-logo.svg';
import cryptoReporterLogo from './images/logos/crypto-reporter-logo.png';
import dpiLogo from './images/logos/dpi-logo.svg';
import nftEveningLogo from './images/logos/nft-evening-logo.png';
import nonprofitsAreMessyLogo from './images/logos/nonprofits-are-messy-logo.png';
import timeLogo from './images/logos/time-logo.svg';
import yfiLogo from './images/logos/yfi-logo.svg';
import endaomentDotOrgPageDT from './images/screenshots/endaomentDotOrgPageDT.png';
import myFundsDT from './images/screenshots/myFundsDT.png';

export const HERO_SLIDES: HeroSlides = [
  {
    title: 'An Evolution in Giving',
    text: 'Give any asset to any organization, or deploy gifts into DeFi investments.',
    href: '/donors',
    image: myFundsDT,
  },
  // {
  //   title: 'Handing board oversight to token holders',
  //   text: 'Learn more about our groundbreaking plans to become the first decentralized nonprofit.',
  // },
  {
    title: 'The Easiest Way to Accept Crypto Donations',
    text: 'No new board policies, no new contracts, no price risk, and donations are delivered as dollars straight to your bank account.',
    href: '/donors',
    image: endaomentDotOrgPageDT,
  },
  {
    title: 'A Community Foundation for Crypto',
    text: 'We are a nonprofit organization dedicated to making a difference in the world through digital assets.',
    href: '/about',
  },
] as const;

export const MEDIA_SLIDES: MediaCards = [
  {
    source: <Image src={cheddarLogo} alt="Cheddar" width={128} height={32} />,
    title: 'Crypto Activists Fight For Reproductive Rights Through NFT, Decentralized Projects',
    snippet:
      'Robert Heeger, President and CEO of Endaoment, joined Closing Bell to discuss crypto fundraising efforts.',
    date: 'July 8, 2022',
    href: 'https://cheddar.com/media/crypto-activists-fight-for-reproductive-rights-through-nft-decentralized-projects',
  },
  {
    source: <Image src={cryptoReporterLogo} alt="Crypto Reporter" width={128} height={32} />,
    title: 'Endaoment Raises $6.67 Million to Further Crypto-Native Planned Giving',
    snippet: 'The financing round was led by Shine Capital, a consumer financial tech and crypto venture fund.',
    date: 'June 23, 2022',
    href: 'https://www.crypto-reporter.com/press-releases/endaoment-raises-6-67-million-to-further-crypto-native-planned-giving-33090/',
  },
  {
    source: <Image src={chronicleOfPhilanthropyLogo} alt="Chronicle of Philanthropy" width={250} height={32} />,
    title: 'Crypto, Meet Donor-Advised Funds: a New Way of Giving',
    snippet:
      'Going forward, digital currencies will create their own sort of community foundations on Endaoment that make grants to causes of the owners’ choice.',
    date: 'June 8, 2021',
    href: 'https://www.philanthropy.com/article/crypto-meet-donor-advised-funds-a-new-way-of-giving',
  },
  {
    source: <Image src={timeLogo} alt="Time" width={98} height={32} />,
    title: 'Ukraine Received More Than $30M in Crypto Donations',
    snippet:
      'The amount of crypto raised for Ukraine-based aid had surpassed $30 million, across a number of funds and efforts',
    date: 'March 1, 2022',
    href: 'https://time.com/6153320/crypto-ukraine-charity/',
  },
  {
    source: <Image src={nftEveningLogo} alt="NFT Evening" width={180} height={32} />,
    title: 'Pussy Riot Launch LegalAbortion.eth To Protect Reproductive Rights',
    snippet:
      'LegalAbortion.eth is an Ethereum wallet that will serve as a donation hub for leading reproductive rights organisations',
    date: 'June 28, 2022',
    href: 'https://nftevening.com/pussy-riot-launch-legalabortion-eth-to-protect-reproductive-rights/',
  },
  {
    source: <Image src={nonprofitsAreMessyLogo} alt="Nonprofits Are Messy Podcast" width={120} height={64} />,
    title: 'Cryptocurrency and the Future of Donor Advised Funds',
    snippet:
      'Here’s the thing. This new financial infrastructure just might be the future of fundraising. It’s fascinating stuff and I invite you to have a listen.',
    date: 'June 26, 2021',
    href: 'https://nftevening.com/pussy-riot-launch-legalabortion-eth-to-protect-reproductive-rights/',
  },
] as const;

export const FAQS: FAQs = [
  {
    title: 'Overview: How does Endaoment Work',
    questions: [
      {
        question: 'Why donate through Endaoment?',
        answer: `
Using Endaoment you can donate cryptocurrencies in their original token form, on-chain. This creates several benefits:

1) The granted amount is equal to the present market value of the donation (i.e., there is no tax on the sale).

2) The potential tax deduction is equal to the market value of the donation - [learn more about taxes and giving](https://www.cointracker.io/blog/crypto-tax-deduction-donor-advised-funds).

3) All actions occur on-chain and are publicly verifiable using a blockchain explorer like [Etherscan](https://etherscan.io).`,
      },
      {
        question: 'What are Donor-Advised Funds?',
        answer: `
A Donor-Advised Fund (DAF) is a charitable account owned and managed by a public charity (that’s us).
DAFs have a Primary Advisor (that’s you), who donates assets into the fund.
Those assets are liquidated into USDC and used when you make grant recommendations to US-based nonprofit organizations.

With a Donor-Advised Fund, you can deposit long-term capital gains assets to your DAF, and deduct the full value of the gift, offsetting taxable income.*
Need more info? Learn all the DAF basics from the [National Philanthropic Trust](https://www.nptrust.org/what-is-a-donor-advised-fund/daf-tax-consideration/).

**Endaoment does not provide tax advice. You should consult with a licensed tax professional to further understand the impact of opening and contributing to a Donor-Advised Fund.*`,
      },
      {
        question: 'What is a Direct Donation?',
        answer: `
A Direct Donation is a gift made from your connected wallet on the organization‘s Endaoment profile.
Direct Donations function just like donations made elsewhere on the Endaoment app, you select the asset and amount, and we send you a tax receipt if requested.
Direct Donations do however bypass the need to create and fund a DAF, allowing users to send their assets to an organization of their choice in a single transaction.`,
      },
      {
        question: 'What are Community Funds?',
        answer: `
Community Funds are open to the public—anyone can donate—and require no setup cost.
These funds carry all the same perks and tax-free status of a traditional DAF, but all grant making decisions are managed by the Endaoment communities.
Based on the stated goals of the fund, the deciding community collectively recommends nonprofit organizations to receive grant awards from that fund.`,
      },
      {
        question: 'What fees are involved? How does Endaoment fund operations?',
        answer: `
Endaoment charges industry-leading low fees, 0.5%  on inbound donations, and 1.0% on outbound on grants, with no annual or recurring fees.
**1.5%** - that‘s it, once!
[Learn more about our fee schedule here](https://docs.endaoment.org/donors/fee-schedule). We're also [generously funded](https://app.endaoment.org/orgs/844661797) by donors like you!`,
      },
      {
        question: 'Has Endaoment undergone a Security Audit?',
        answer: `
Yes. All our smart contracts have been audited by world-renowned blockchain research firm [OpenZeppelin](https://openzeppelin.com).
Don't just take our word for it - take a look at their [public audit report](https://blog.openzeppelin.com/endaoment-audit).`,
      },
    ],
  },
  {
    title: 'Donors: Donate Crypto, Grant Dollars',
    questions: [
      {
        question: 'What Tokens/Chains/Wallets are supported?',
        answer: `
Endaoment‘s application is built on Ethereum, but we [accept any crypto or digital asset](https://docs.endaoment.org/governance/policies-and-procedures#gift-acceptance-policies), regardless of chain or type (yes, even NFTs!), as donations.
To use our application, you‘ll need an [Ethereum-enabled wallet](https://ethereum.org/en/wallets/) installed in your web browser. We support [MetaMask](https://metamask.io), [Wallet Connect](https://walletconnect.com), and [Coinbase Wallet](https://wallet.coinbase.com). Simply navigate to the [Endaoment app](http://app.endaoment.org) and click ‘Login‘ to connect your wallet. [Checkout our tutorials with screenshots for more](https://docs.endaoment.org/donors/getting-started/connecting-your-wallet). We also accept over-the-counter donations of many other cryptocurrencies, please refer to [this page](/otc) for more details.`,
      },
      {
        question: 'How do I start a Donor-Advised Fund? What does it cost?',
        answer: `
After connecting your wallet with the [Endaoment app](https://app.endaoment.org), click 'Start a new fund' to open a DAF.
Each Fund has a contract address on chain. Fill out your contact info, and send us a small amount of ETH to cover the GAS for deploying the Fund (~.02 ETH, depending on gas prices).
Want more details? [Check out our tutorial with screenshots](https://docs.endaoment.org/donors/getting-started/starting-a-fund).
If you prefer not to start your own fund, you can donate to a Community Fund and still receive a tax receipt for your gift.`,
      },
      {
        question: 'Can I donate to an Organization directly?',
        answer: `
Certainly! If you are donating an asset from your Ethereum wallet, simply navigate to the Organization's Endaoment profile page and click donate.
You can also donate to an Org directly using an OTC donation if your asset isn‘t on the Ethereum blockchain (see below).`,
      },
      {
        question: 'Can I donate to a Fund without using the app?',
        answer: `Certainly! We accept over the counter donations. Please refer to [this page](/otc) for more details.`,
      },
      {
        question: 'What is a Donation?',
        answer: `
Any Donation (including both direct donations as well as fund donations) is an irrevocable gift of cryptocurrency to Endaoment.
After connecting your wallet to the [Endaoment app](https://app.endaoment.org) and selecting a DAF or Community Fund to donate to, you can make a donation by picking what type of cryptocurrency you want to donate ([see full list of supported assets here](https://docs.endaoment.org/governance/policies-and-procedures#gift-acceptance-policies)).
After you sign and submit the transaction we‘ll programmatically exchange your donated assers for USDC via decentralized exchange provider [Uniswap](https://uniswap.org/), and deposit them into your selected fund‘s smart contract.
Lastly, we'll send you a tax receipt. Want more details? [Check out our tutorial with screenshots](https://docs.endaoment.org/donors/getting-started/making-a-donation).`,
      },
      {
        question: 'What is a Grant Recommendation?',
        answer: `
A grant recommendation is an instruction for Endaoment to transfer or 'award' money from a fund to a qualified nonprofit organization.
After making a donation into your Endaoment DAF, search Endaoment for organizations you'd like to support.
Want more details? [Here's our tutorial with screenshots](https://docs.endaoment.org/donors/getting-started/recommending-grants).

Note: If the organization you've selected does not yet have an Endaoment smart contract deployed on-chain, we'll ask for your help to deploy an address for them using a small amount of ETH.
This is a one-time setup, and costs about the same as opening a Fund.
Please note that grant recomemendations do not apply if you are making a direct donation as they only apply when reccomending grants from a fund.`,
      },
      {
        question: 'What do I need to do for taxes?*',
        answer: `
If you plan on taking a tax deduction based on your charitable contributions, you will need the tax receipt we email you, alongside [IRS form 8283](https://www.irs.gov/pub/irs-pdf/f8283.pdf) if your gift exceeds $500.
If your gift exceeds $5,000, you will need to complete Section B and provide a qualified appraisal of the gift.
If you have questions, please email us at [info@endaoment.org](mailto:info@endaoment.org).

**Endaoment does not provide tax advice. You should consult with a licensed tax professional to further understand the impact of opening and contributing to a Donor-Advised Fund.*`,
      },
    ],
  },
  {
    title: 'Organizations: Claim Org, Receive Grants',
    questions: [
      {
        question: 'Is Endaoment a 501(c)(3) tax-exempt nonprofit?',
        answer: `
Yes. Endaoment is a California-based Public Charity and Nonprofit Public Benefit Corporation, federally tax-exempt under IRS Rev. Code Sec 501(c)(3).
Our EIN is 84-4661797. Check out our [IRS determination letter](https://drive.google.com/file/d/13kLcgBYVf47xNRU-DmLkwbp9PbinkI_G/view?usp=sharing) or see [our governance documentation](https://docs.endaoment.org/governance/mission-values) to learn more.`,
      },
      {
        question: 'How do I receive granted funds from Endaoment?',
        answer: `
At Endaoment we offer two options: withdraw to your organization‘s bank account as US Dollars via ABA bank wire or to your organization‘s cryptocurrency wallet as [US Dollar Coins (USDC)](https://www.circle.com/en/usdc).
This can only occur after you go through the claim your organization step (see below) as only approved fiduciaries can accept grants on behalf of their organization.
As soon as the grant is accepted, we‘ll initiate the payout process.

Wires cost $15 (deducted from the grant) and typically arrive within two business days, digital wallet transfers are free and near instantaneous. The method your organization uses is entirely up to you!`,
      },
      {
        question: 'How do I claim my Organization?',
        answer: `
First, navigate to your Endaoment profile by adding your EIN to this URL: *app.endaoment.org/orgs/[YOUR_EIN]*. For example, Endaoment‘s EIN is 84-4661797 so our page is [https://app.endaoment.org/orgs/844661797](https://app.endaoment.org/orgs/844661797).
Once there, click 'Is this your organization?' and select how your organization will receive Endaoment grants.
Once your claim is reviewed, an Endaoment staff member will reach out to conduct due-diligence and verification.

Want more details? [Check out our tutorial with screenshots](https://docs.endaoment.org/organizations/claiming-an-organization/submitting-a-claim).
Or, feel free to send us an email at [info@endaoment.org](mailto:info@endaoment.org) if you have any questions about this process.`,
      },
      {
        question: 'How do I accept Endaoment grants? ',
        answer: `
After claiming your organization, you can accept grants via email.
Simply reply to grant award emails that come from Endaoment just as you would with any other grant from another DAF provider or philanthropic institution.
If you have questions about specific grant awards or grant instructions, please feel free to reach out to us at [info@endaoment.org](mailto:info@endaoment.org).`,
      },
      {
        question: 'Can I change the data displayed for my Organization? ',
        answer: `
Absolutely. We pull all that information from Guidestar, but would be happy to adjust non financial information if it‘s out of date, simply fill out (this form)[https://endaoment.typeform.com/update-org] and we‘ll be in touch.`,
      },
    ],
  },
] as const;

// TODO: All items are missing optional URL props
export const CAREERS: Roles = [
  { title: 'Chief Revenue Officer', description: 'Remote' },
  { title: 'Senior FE Web Dev', description: 'Remote' },
  { title: 'Administrator', description: 'Remote' },
] as const;

export const BOARD_MEMBERS: BoardMembers = [
  {
    name: 'Robbie Heeger',
    position: 'President & CEO',
    description: 'Founded Endaoment in 2019. Experienced consumer technology operator & builder.',
    avatar: robbie,
  },
  {
    name: 'Carter Wilkinson',
    position: 'Treasurer & Secretary',
    description: 'Startup veteran and operational expert focused on entity architecture and legal compliance.',
    avatar: carter,
  },
  {
    name: 'Ruth Bender',
    position: 'Director',
    description: 'Philanthropy and donor-advised fund expert with two decades of domain specialization.',
    avatar: ruth,
  },
  {
    name: 'Vance Spencer',
    position: 'Director',
    description: 'GP & Founder of Framework Ventures providing value and insight into global crypto adoption.',
    avatar: vance,
  },
] as const;

export const TEAM_MEMBERS: TeamMembers = [
  {
    name: 'Robbie Heeger',
    position: 'President & CEO',
    avatar: robbie,
  },
  {
    name: 'Zach Bronstein',
    position: 'Chief Operating Officer',
    avatar: zach,
  },
  {
    name: 'Dae Moyer',
    position: 'Communications & Community',
    avatar: dae,
  },
  {
    name: 'Alexis Miller',
    position: 'Partnerships & Donor Relations',
    avatar: alexis,
  },
  {
    name: 'Ricardo Pedroni',
    position: 'Technical Lead',
    avatar: ricardo,
  },
  {
    name: 'Noah Gallant',
    position: 'Human Interface & Protocol Design',
    avatar: noah,
  },
  {
    name: 'Pedro Ornelas',
    position: 'Software Engineering',
    avatar: pedro,
  },
  {
    name: 'Ishaan Bharal',
    position: 'Software Engineering',
    avatar: ishaan,
  },
  {
    name: 'Dania Luc',
    position: 'Technical Project Management',
    avatar: dania,
  },
] as const;

export const PORTFOLIO_ALLOCATION_SLIDES: PortfolioAllocationCards = [
  {
    name: 'Ethereum',
    logo: <EthereumIcon width={52} height={67} color="#ADBACC" strokeColor="#ADBACC" />,
    type: 'Single Token - ETH',
    description:
      'Ethereum is a global, open-source platform for decentralized applications. In other words, the vision is to create a world computer that anyone can build applications in a decentralized manner; while all states and data are distributed and publicly accessible. ',
    depositCap: 10_000_000,
  },
  {
    name: 'DeFi Pulse Index',
    logo: <Image src={dpiLogo} width={67} height={67} alt="DPI" />,
    type: 'Single Token - DPI',
    description:
      'The DeFi Pulse Index is a capitalization-weighted index that tracks the performance of decentralized financial (DeFi) assets across Ethereum. It is a 21st century digital upgrade to structured products.',
    depositCap: 2_000_000,
  },
  {
    name: 'Compound',
    logo: <Image src={compoundLogo} width={52} height={67} alt="Compound" />,
    type: 'Stable Yield - cUSDC',
    description:
      'The Compound interest rate market runs on the Ethereum blockchain. When Endaoment supplies USDC to the Compound Protocol, they begin earning a variable interest rate instantly.',
  },
  {
    name: 'Yearn',
    logo: <Image src={yfiLogo} width={52} height={67} alt="Yearn" />,
    type: 'Stable Yield - yUSDC',
    description:
      'The Yearn protocol pools together dollar stablecoins to create a single stablecoin pool for easy transfers between stablecoins, earning a variable interest rate on deposits.',
  },
  {
    name: 'AAVE',
    logo: <Image src={aaveLogo} width={52} height={67} alt="AAVE" />,
    type: 'Stable Yield - aUSDC',
    description:
      'The AAVE protocol uses smart contracts to facilitate loans of crypto assets through a network of borrowers and sellers. Depositers earn a variable interest rate on their loans.',
  },
] as const;

// TODO: Replace with real content
export const RELEASE_TIMELINE: Timeline = [
  {
    label: 'July \'22',
    detail:
      'The Endaoment Protocol v2, including the NDAO token contract, completes external audit by leading researchers at Least Authority.',
  },
  {
    label: 'Aug \'22',
    detail:
      'Endaoment announces the NDAO token, system architecture, initial distribution and airdrop of tokens to the Endaoment community.',
  },
  {
    label: 'Dec \'22',
    detail:
      'Likely NDAO Token Launch and initial airdop to users based on their participation in the Endaoment ecosystem.',
  },
  {
    label: 'March \'23',
    detail:
      'Endaoment.Org holds elections during their annual meeting. Users select new Directors via snapshot voting and NVT ownership.',
  },
  {
    label: 'Ongoing',
    detail:
      '12-week Benefit Cycles release new NDAO & USDC token rewards to NVT holders based on activity and participation.',
  },
] as const;
