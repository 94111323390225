import { IconProps } from './Icon.types';

export const TwitterIcon = ({ width = 42, height = 34, color = 'currentColor', ...props }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M42 4.02474C40.4548 4.70752 38.7939 5.16878 37.0511 5.37629C38.83 4.31404 40.1964 2.63199 40.8397 0.627562C39.1747 1.61136 37.3307 2.32552 35.3677 2.71043C33.7961 1.04216 31.5567 0 29.0782 0C24.3196 0 20.4614 3.84295 20.4614 8.5831C20.4614 9.25585 20.5376 9.91103 20.6845 10.5392C13.5231 10.1813 7.17397 6.76403 2.92397 1.57099C2.18226 2.83865 1.75721 4.31301 1.75721 5.88609C1.75721 8.86405 3.27846 11.4912 5.59055 13.0304C4.1781 12.9858 2.84942 12.5997 1.6877 11.9569C1.68672 11.9926 1.68672 12.0286 1.68672 12.0648C1.68672 16.2235 4.65697 19.6923 8.59888 20.4814C7.87586 20.6776 7.1146 20.7825 6.32877 20.7825C5.77354 20.7825 5.23384 20.7285 4.70758 20.6284C5.8042 24.0384 8.98633 26.5199 12.7571 26.5889C9.80807 28.8913 6.09274 30.2636 2.05548 30.2636C1.35996 30.2636 0.674102 30.2228 0 30.1435C3.8134 32.579 8.34268 34 13.2088 34C29.0582 34 37.7255 20.9212 37.7255 9.57822C37.7255 9.20606 37.7171 8.83601 37.7005 8.46764C39.3839 7.25772 40.8448 5.74612 42 4.02474Z"
      fill={color}
    />
  </svg>
);
