import { IconProps } from './Icon.types';

export const MirrorIcon = ({ width = 32, height = 40, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 15.4839C0 6.93237 7.12493 0 15.914 0C24.7031 0 31.828 6.93237 31.828 15.4839V37.6471C31.828 38.9466 30.7453 40 29.4096 40H2.41831C1.08272 40 0 38.9466 0 37.6471V15.4839Z"
        fill="url(#mirror-paint)"
      />
      <defs>
        <linearGradient
          id="mirror-paint"
          x1="4.09667"
          y1="2.30629"
          x2="30.8648"
          y2="45.9476"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="rgba(173, 186, 204, 0.0)" />
          <stop offset="0.734375" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  );
};
