import { DiscordIcon, MailIcon, MirrorIcon, TwitterIcon } from '@endaoment-frontend/ui/icons';
import { Card } from '@endaoment-frontend/ui/shared';

import styles from './SocialLinks.module.scss';

export const SocialLinks = () => {
  return (
    <section id="social-links" className={styles['social-container']}>
      <Card
        as="a"
        className={styles['social-card']}
        href="https://discord.gg/endaoment"
        target="_blank"
        rel="noreferrer"
      >
        <DiscordIcon />
        <span>Discord</span>
      </Card>
      <Card
        as="a"
        className={styles['social-card']}
        href="https://twitter.com/endaomentdotorg"
        target="_blank"
        rel="noreferrer"
      >
        <TwitterIcon />
        <span>Twitter</span>
      </Card>
      <Card as="a" className={styles['social-card']} href="mailto:hello@endaoment.org" target="_blank" rel="noreferrer">
        <MailIcon />
        <span>Email</span>
      </Card>
      <Card
        as="a"
        className={styles['social-card']}
        href="https://mirror.xyz/endaoment.eth"
        target="_blank"
        rel="noreferrer"
      >
        <MirrorIcon />
        <span>Mirror</span>
      </Card>
    </section>
  );
};
