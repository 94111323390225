import Head from 'next/head';

const DEFAULT_TITLE = 'Endaoment';
const DEFAULT_DESCRIPTION =
  "We're a tax-exempt Community Foundation built for decentralized finance and focused on social impact. Open a fund and make giving your new favorite habit.";
const DEFAULT_IMAGE = 'https://storage.googleapis.com/endaoment-static/endaoment-meta-preview.png';

export const MetaTags = ({
  title = DEFAULT_TITLE,
  url,
  image = DEFAULT_IMAGE,
  description = DEFAULT_DESCRIPTION,
}: {
  title?: string;
  url: string;
  image?: string;
  description?: string;
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:url" content={`https://endaoment.org${url}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="endaoment.org" />
      <meta property="twitter:url" content={`https://endaoment.org${url}`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Head>
  );
};
