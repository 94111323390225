import clsx from 'clsx';

import styles from './Pill.module.scss';
import { PillProps } from './Pill.types';

export const Pill = ({ children, className, outline, variation, fill, size = 'small', onClick }: PillProps) => {
  return (
    <div
      className={clsx(
        styles['pill'],
        className,
        onClick && styles['selectable'],
        outline && styles['outline'],
        fill && styles['fill'],
        styles[`size-${size}`],
        variation && styles[`color-${variation}`],
      )}
      onClick={onClick}
      onKeyDown={(e) => e.key === 'Enter' && onClick?.()}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};
