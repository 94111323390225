import { IconProps } from './Icon.types';

export const DiscordIcon = ({ width = 47, height = 35, color = 'currentColor', ...props }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 47 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.7875 3.11672C36.7919 1.79538 33.5795 0.821871 30.2208 0.264302C30.1596 0.253541 30.0985 0.280433 30.067 0.334218C29.6539 1.04059 29.1963 1.96211 28.8758 2.68642C25.2633 2.16651 21.6693 2.16651 18.1309 2.68642C17.8104 1.94601 17.3361 1.04059 16.9211 0.334218C16.8896 0.282228 16.8285 0.255335 16.7673 0.264302C13.4105 0.820089 10.1981 1.7936 7.20066 3.11672C7.17472 3.12748 7.15247 3.14543 7.13771 3.16872C1.04456 11.9196 -0.624611 20.4554 0.194228 28.8854C0.197933 28.9266 0.222016 28.9661 0.255363 28.9911C4.27545 31.8292 8.16959 33.5522 11.9914 34.6942C12.0526 34.7121 12.1174 34.6906 12.1563 34.6422C13.0604 33.4554 13.8663 32.2039 14.5572 30.8879C14.598 30.8109 14.5591 30.7194 14.4757 30.689C13.1975 30.2228 11.9803 29.6545 10.8095 29.0091C10.7169 28.9571 10.7094 28.8298 10.7946 28.7688C11.041 28.5913 11.2875 28.4066 11.5227 28.2202C11.5653 28.1861 11.6246 28.179 11.6747 28.2005C19.3665 31.5764 27.6939 31.5764 35.2949 28.2005C35.345 28.1772 35.4043 28.1844 35.4487 28.2184C35.6841 28.4049 35.9304 28.5913 36.1787 28.7688C36.2639 28.8298 36.2583 28.9571 36.1657 29.0091C34.9949 29.667 33.7777 30.2228 32.4976 30.6872C32.4142 30.7177 32.3772 30.8109 32.418 30.8879C33.1237 32.2021 33.9296 33.4535 34.817 34.6404C34.8541 34.6906 34.9207 34.7121 34.9819 34.6942C38.8223 33.5522 42.7164 31.8292 46.7365 28.9911C46.7717 28.9661 46.7939 28.9284 46.7976 28.8872C47.7776 19.1412 45.1562 10.6754 39.8486 3.1705C39.8356 3.14543 39.8134 3.12748 39.7875 3.11672ZM15.7059 23.7524C13.3901 23.7524 11.482 21.7086 11.482 19.1986C11.482 16.6886 13.3531 14.6448 15.7059 14.6448C18.0771 14.6448 19.9668 16.7065 19.9297 19.1986C19.9297 21.7086 18.0586 23.7524 15.7059 23.7524ZM31.323 23.7524C29.0073 23.7524 27.0992 21.7086 27.0992 19.1986C27.0992 16.6886 28.9703 14.6448 31.323 14.6448C33.6944 14.6448 35.584 16.7065 35.547 19.1986C35.547 21.7086 33.6944 23.7524 31.323 23.7524Z"
      fill={color}
    />
  </svg>
);
